import React from "react"
import { Link } from "gatsby"
import "../timmi.css"

export default function Starring({
  title,
  text,
  pic,
  caption,
  more,
  moreLink,
}) {
  function wantMore() {
    if (more && moreLink)
      return (
        <footer>
          <Link to={moreLink}>{more}</Link>
        </footer>
      )
    else if (more) {
      return <footer>{more}</footer>
    } else {
      return ""
    }
  }

  return (
    <article className="starring">
      <header>
        <h1 className="starringHeading">{title}</h1>
      </header>
      <figure>
        <img src={pic} width="50%"/>
        <figcaption>{caption}</figcaption>
      </figure>
      <section>
        <p>{text}</p>
      </section>
      {wantMore()}
    </article>
  )
}
