// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bookclub-tsx": () => import("./../../src/pages/bookclub.tsx" /* webpackChunkName: "component---src-pages-bookclub-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intro-tsx": () => import("./../../src/pages/intro.tsx" /* webpackChunkName: "component---src-pages-intro-tsx" */),
  "component---src-pages-lab-tsx": () => import("./../../src/pages/lab.tsx" /* webpackChunkName: "component---src-pages-lab-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */)
}

